/* src/components/Rainy.css */
.rainy {
    background: linear-gradient(to bottom, #555, #999, #87ceeb);
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  
  .cloud {
    position: absolute;
    left: 50%;
    width: 300px;
    height: auto;
    transform: translateX(-50%);
    animation: move-clouds 30s linear infinite ;
    z-index: 4;
  }
  .dark-cloud {
    position: absolute;
    left: 0;
    top: -10%;
    width: 600px;
    height: auto;
    transform: translateX(-50%);
    animation: move-dark-clouds 50s linear infinite;
    z-index: 2;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  
  .raindrops {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  .raindrop {
    position: absolute;
    width: 2px;
    height: 10px;
    background-color: #9fbfdf;
    top: 30px;
    animation: fall 2s linear infinite, sway 1s linear infinite alternate;
  }
  
  .raindrop:nth-child(1) { left: 5%; top: -5%; animation-delay: 0s; }
  .raindrop:nth-child(2) { left: 15%; top: -10%; animation-delay: 0.2s; }
  .raindrop:nth-child(3) { left: 25%; top: -15%; animation-delay: 0.4s; }
  .raindrop:nth-child(4) { left: 35%; top: -20%; animation-delay: 0.6s; }
  .raindrop:nth-child(5) { left: 45%; top: -25%; animation-delay: 0.8s; }
  .raindrop:nth-child(6) { left: 55%; top: -30%; animation-delay: 1s; }
  .raindrop:nth-child(7) { left: 65%; top: -35%; animation-delay: 1.2s; }
  .raindrop:nth-child(8) { left: 75%; top: -40%; animation-delay: 1.4s; }
  .raindrop:nth-child(9) { left: 85%; top: -45%; animation-delay: 1.6s; }
  .raindrop:nth-child(10) { left: 95%; top: -50%; animation-delay: 1.8s; }

  
  @keyframes move-clouds {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
  }
  @keyframes move-dark-clouds {
    0% { transform: translateX(-100vw); }
    100% { transform: translateX(100vw); }
  }
  
  @keyframes fall {
    0% { top: -5%; opacity: 1; }
    100% { top: 110%; opacity: 0; }
  }
  
  @keyframes sway {
    0%, 100% { transform: translateX(0); }
    50% { transform: translateX(5px); }
  }

  