#app {
    height: 100vh;
    display: flex;
    width: 100%;
}

#sidebar {
    height: 100vh;
}

.main-content {
    flex: 1;
    padding: 2rem;
    height: 100vh;
    background-color: white;
}

.mati {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
}

.bootmati {
    height: 100vh;
}

.allmetirials {
    width: 100%;
    /* height: 80vh; */
    display: flex;
    justify-content: center;
    align-items: center;

}

.allmeti {
    width: 70%;
}

.file-inputs input,
Select {
    border: black 3px solid;
}

.matform {
    width: 50%;
    height: 20vh;
}


@media (max-width:500px) {
    #sidebar {
        position: fixed;
    }

    .main-content {
        padding-left: 5rem;
        width: 100%;
    }
}