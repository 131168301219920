.blank-input {
  width: 15%;
  outline: none;
  border-bottom: 1px solid black;
  border: 'none';
  margin-top: 10px;
  padding: 5px;
}
/* .scores{
  overflow-y: scroll;
} */
.boxscores {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

@media (max-width:500px) {
  .blank-input {
    width: 25%;
  }

  .boxscores {
    display: grid;
    justify-content: space-evenly;
  }
}

.expandable-task {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expandable-task.expanded {
  white-space: normal;
  overflow: wrap;
  text-overflow: unset;
}
