.list {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;
}

.listgrid{
    width: 50%;
    height: 300px;
    display: flex;
    justify-content: center;
}

.listgrid h6
{
    text-align: center;
}

@media (max-width:700px) {
    .list {
        width: 100%;
        height:100%;
    }
    .listgrid{
        margin-top: 10%;
        width: 200px;
        height: 100px;
    }
}