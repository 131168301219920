.h1-heading {
  font-size: 4rem;
  font-weight: bold;
  background: linear-gradient(270deg, rgb(12, 214, 46), rgb(137, 119, 244), rgb(218, 10, 218), #4f3aea, rgb(70, 1, 70), rgb(227, 22, 22), #34036c, rgb(31, 185, 219));
  background-size: 400% 400%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: fadeIn 2s ease-in-out, gradientBackground 8s ease infinite;
  transition: transform 0.3s ease-in-out;
  text-align: center;
  margin-bottom: 1%;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.h1-animation:hover {
  transform: scale(1.1);
}

@media (max-width:500px) {
  .h1-animation {
    margin-top: 20%;
    font-size: 1.5em;
  }
  .h1-heading {
    font-size: 2rem;
   }
}