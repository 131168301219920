.home-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    margin-bottom: 20px;

}

.homename {
    text-align: center;
    background: linear-gradient(180deg, #100445, #ff01a2);
    font-size: 80px;
    font-family: poppins;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.top-5 {
    font-size: 30px;
    /* margin-top: 5%; */
    color: #007acc;
    text-align: center;
    margin-bottom: 20px;
}

.studetail {
    border-collapse: collapse;
    max-width: 1210px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    text-align: center;


}

.studetail td {
    padding: 10px;
    text-align: center;

}


.fivestreak {
    margin-top: 5%;
    display: flex;
    width: 100%;
    justify-content: center;
}

.streakgrid {
    /* margin-top: 5%; */
    /* display: flex; */
    width: 100%;
    /* justify-content: space-around; */
}


.streakdiv {
    margin-top: 5%;
    display: flex;
    width: 100%;
    justify-content: center;
}

.graphinput {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.graphdiv {
    width: 100%;
   
}

.graphinput input {
    height: 5vh;
}



.labelinput tr td input
{
    width: 20px;
}
.labelinput tr td 
{
    width: 100%;
justify-content: center;

}





.streak-text {
    width: 150%;
    height: 5vh;
    background-image: url("./streak.png");
    background-repeat: no-repeat;
    background-size: 150% 100%;
    color: black;
    font-size: 15px;
    display: flex;
    justify-content:flex-end;
    align-items: center;
}
.main-streak-text{
    width: 100%;
    height: 5vh;
    background-image: url("./streak.png");
    background-repeat: no-repeat;
    object-fit: cover;
    color: black;
    background-size: 100% 100%;
    font-size: 10px;
    padding-top: 20px !important;
    padding: 9px;
    display: flex;
    justify-content:center;
    align-items: center;
    font-weight: bold;
}

img {
    align-items: center;
    max-width: 100%;
    height: 70px;
}

.boothack{
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 700px) {
    .studetail {
        font-size: 12px;
        width: 100%;
    }

    .graphdiv {
        padding-left: 0%;
        margin-left: 0%;
    }
 

    .homename {
        font-size: 50px;
    }

    .top-5 {
        font-size: 24px;
    }

    img {
        height: 50px;
        width: 120px
    }

    .streak-text {
        font-size: 11px;
    }

    .streakgrid {
        width: 100%;
    }

    .streakgrid table tbody {
        background-color: white;
    }


    .studetail td {
        padding: 2px;
    }
}