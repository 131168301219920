

.background-container {
  min-height: 100vh;
  max-height: 60em;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex: 1;
  position: relative;
  background: linear-gradient(to bottom, #B9DFFF, #4BA0DB, #004390);
  overflow: hidden;
  backdrop-filter: blur(2px);
}
.front, .middle, .back {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60em;
  width: 100%;
}
.front {
  z-index: 3;
}
.middle {
  z-index: 2;
}
.back {
  z-index: 1;
}

.ground {
  position: absolute;
  bottom: 0;
  right: -1em;
  background: linear-gradient(to bottom, #658433, #36471b);
  height: 6em;
  width: 101%;
  border-radius: 50% 50% 0 0;
  box-shadow: inset 0 .5em 1em lightyellow;
}

.tree.one > .top {
  position: absolute;
  bottom: 12em;
  right: 7em;
  background: linear-gradient(to bottom, #99AC5E, #658433);
  height: 10em;
  width: 10em;
  border-radius: 50%;
  box-shadow: inset .5em 1em 5em lightyellow;
}
.tree.one > .overlap {
  position: absolute;
  bottom: 12.5em;
  right: 10em;
  background: linear-gradient(to bottom, transparent, #99AC5E 98%);
  height: 2em;
  width: 3.5em;
  border-radius: 50%;
  box-shadow: 0 .3em .2em #36471b;
  z-index: 2;
}
.tree.one > .trunk {
  position: absolute;
  bottom: 3em;
  right: 11em;
  background: linear-gradient(#8e724e, #422f15);
  height: 10em;
  width: 1.5em;
  border-radius: .1em;
  box-shadow: inset .1em 0em .2em lightyellow;
}

.tree.two > .top {
  position: absolute;
  bottom: 10em;
  right: 24em;
  background: linear-gradient(to bottom, #99AC5E, #658433);
  height: 7em;
  width: 7em;
  border-radius: 50%;
  box-shadow: inset .5em 1em 5em lightyellow;
}
.tree.two > .overlap {
  position: absolute;
  bottom: 10.5em;
  right: 26em;
  background: linear-gradient(to bottom, transparent, #99AC5E 98%);
  height: 2em;
  width: 2.5em;
  border-radius: 50%;
  box-shadow: 0 .3em .2em #36471b;
  z-index: 2;
}
.tree.two > .trunk {
  position: absolute;
  bottom: 3em;
  right: 26.5em;
  background: linear-gradient(#8e724e, #422f15);
  height: 8em;
  width: 1.5em;
  border-radius: .1em;
  box-shadow: inset .1em 0em .2em lightyellow;
}
.tree.three > .top {
  position: absolute;
  bottom: 12em;
  right: 57em;
  background: linear-gradient(to bottom, #99AC5E, #658433);
  height: 9em;
  width: 9em;
  border-radius: 50%;
  box-shadow: inset .5em 1em 5em lightyellow;
}
.tree.three > .overlap {
  position: absolute;
  bottom: 12.5em;
  right: 60.5em;
  background: linear-gradient(to bottom, transparent, #99AC5E 98%);
  height: 2em;
  width: 2.5em;
  border-radius: 50%;
  box-shadow: 0 .3em .2em #36471b;
  z-index: 2;
}
.tree.three > .trunk {
  position: absolute;
  bottom: 3em;
  right: 61em;
  background: linear-gradient(#8e724e, #422f15);
  height: 10em;
  width: 1.5em;
  border-radius: .1em;
  box-shadow: inset .1em 0em .2em lightyellow;
}


.hill {
  background: linear-gradient(to bottom, #cbe2a5, #658433);
  border-radius: 40em 50em 0 0;
  box-shadow: inset -1em 1em 5em lightyellow;
  height: 30em;
  width: 20em;
}
.hill.one {
  background: linear-gradient(to bottom, #99AC5E, #658433);
  position: absolute;
  bottom: -5em; 
  right: 80em;
}
.hill.two {
  background: linear-gradient(to bottom, #658433, #36471b);
  position: absolute;
  bottom: -10em; 
  right: 70em;
}
.hill.three {
  background: linear-gradient(to bottom, #658433, #36471b);
  position: absolute;
  bottom: -10em; 
  right: 95em;
}
.hill.four {
  position: absolute;
  bottom: 5em; 
  right: 90em;
  z-index: -1;
}

.sun-rays {
position: absolute;
bottom: 32em;
right: 18em;
height: 15em;
width: 15em;
z-index: -5;
background: radial-gradient(rgba(255, 237, 175, .8) 50%, rgba(255, 237, 175, .2) 70%);
border-radius: 50%;
box-shadow: 0 0 .5em rgba(255, 237, 175, 1);
-webkit-animation: rays 10s infinite;
-moz-animation: rays 10s infinite;
animation: rays 10s infinite;
}
.sun {
position: absolute;
bottom: 34.5em;
right: 20.5em;
height: 10em;
width: 10em;
z-index: -5;
background: rgba(255, 237, 175, 1);
border-radius: 50%;
box-shadow: 0 0 1em rgba(255, 237, 175, .5), inset 0 0 .5em #FFFFAD;
}

.person {
  position: absolute;
  bottom: 4.8em;
  right: 40em;
  height: 20em;
  width: 15em;
  z-index: 89;
}

.head {
  position: absolute;
  background: linear-gradient(#f9cc36, #cea212);
  height: 1.5em;
  width: 1.5em;
  bottom: 5.3em;
  right: 7em;
  border-radius: 50%;
  z-index: 3;
  box-shadow: inset 0 .2em .2em lightyellow;
}
.coat {
  position: absolute;
  background: linear-gradient(#f9cc36, #cea212);
  height: 3.5em;
  width: 2.5em;
  bottom: 2em;
  right: 6.5em;
  border-radius: 5em 5em 20% 20%;
  z-index: 2;
}
.legs {
  position: absolute;
  background: linear-gradient(#454545, #000000);
  height: 3em;
  width: 1.5em;
  bottom: 0;
  right: 7em;
  border-radius: 0 0 20% 20%;
  box-shadow: 0 .3em .5em #27380e;
}
.arm {
  position: absolute;
  background: linear-gradient(#f9cc36, #cea212);
  height: 1.5em;
  width: .5em;
  bottom: 3.5em;
  right: 6em;
  border-radius: 20%;
  transform: rotate(10deg);
  z-index: 2;
}
.string {
  position: absolute;
  background: #f3f3f3;
  height: 5em;
  width: .1em;
  bottom: 4.9em;
  right: 6.1em;
  border-radius: 20%;
  transform: rotate(5deg);
  z-index: 2;
  -webkit-animation: string 10s infinite;
	-moz-animation: string 10s infinite;
	animation: string 10s infinite;
}
.balloon {
  position: absolute;
  background: radial-gradient(closest-side, rgba(106, 0, 193, .2), rgba(106, 0, 193, .8) 85%);
  height: 3em;
  width: 2.5em;
  bottom: 9.8em;
  right: 4.8em;
  border-radius: 50% 50% 50% 50% / 40% 40% 60% 60%;
  box-shadow: inset 0 .2em .2em lightyellow;
  transform: rotate(185deg);
  z-index: 2;
  -webkit-animation: flow 10s infinite;
	-moz-animation: flow 10s infinite;
	animation: flow 10s infinite;
}
.cloud.one {
  position: absolute;
  top: 20em;
  left: 5em;
  z-index: 2;
  -webkit-animation: moveCloud 35s linear infinite;
	-moz-animation: moveCloud 35s linear infinite;
	animation: moveCloud 35s linear infinite;
	
	-webkit-transform: scale(0.7);
	-moz-transform: scale(0.7);
	transform: scale(0.7);
}
.cloud.two {
  position: absolute;
  left: 80em;
  top: 25em;
  z-index: -2;
  -webkit-animation: moveCloud 45s infinite linear;
	-moz-animation: moveCloud 45s infinite linear;
	animation: moveCloud 45s infinite linear;
	
	-webkit-transform: scale(0.9);
	-moz-transform: scale(0.9);
	transform: scale(0.9);
}
.cloud-body {
  position: absolute;
  top: 10em;
  background: #ffffff;
  box-shadow: inset 0 .3em .5em lightyellow, 0 0 .5em #ffffff;
  height: 9em;
  width: 30em;
  border-radius: 10em;
}
.cloud-top {
  position: absolute;
  top: 4em;
  left: 3em;
  background: #ffffff;
  box-shadow: inset 0 .3em .5em lightyellow, 0 0 .5em #ffffff;
  height: 13em;
  width: 12em;
  border-radius: 10em 10em 10% 30%;  
}
.cloud-puff {
  position: absolute;
  top: 6em;
  left: 11em;
  background: #ffffff;
  box-shadow: inset 0 .3em .5em lightyellow, 0 0 .5em #ffffff;
  height: 12em;
  width: 12em;
  border-radius: 50%;  
}

/*Cloud Animation*/
@-webkit-keyframes moveCloud {
    0% {
        left: -25em;
    }
    100% {
        left: 50em;
    }
}

@-moz-keyframes moveCloud {
    0% {
        left: -25em;
    }
    100% {
        left: 50em;
    }
}

@keyframes moveCloud {
    0% {
        left: -25em;
    }
    100% {
        left: 100em;
    }
}

/*Sun Animation*/
@-webkit-keyframes rays {
    0% {
        transform: scale(1.1);
    }
    25% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1.1);
    }
    75% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1.1);
    }
}

@-moz-keyframes rays {
    0% {
        transform: scale(1.1);
    }
    25% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1.1);
    }
    75% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1.1);
    }
}

@keyframes rays {
    0% {
        transform: scale(1.1);
    }
    25% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1.1);
    }
    75% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1.1);
    }
}

/*Balloon Animation*/
@-webkit-keyframes flow {
    0% {
        transform: rotate(20deg);
    }
    50% {
        transform: rotate(0deg);
    }
     100% {
        transform: rotate(20deg);

    }
}

@-moz-keyframes flow {
    0% {
        transform: rotate(10deg);
        transform-origin: bottom;
    }
    50% {
        transform: rotate(0deg);
        transform-origin: bottom;
    }
     100% {
        transform: rotate(10deg);
        transform-origin: bottom;
    }
}

@keyframes flow {
    0% {
        transform: rotate(10deg);
        transform-origin: bottom;
    }
    50% {
        transform: rotate(0deg);
        transform-origin: bottom;
    }
     100% {
        transform: rotate(10deg);
        transform-origin: bottom;
    }
}

/*String Animation*/
@-webkit-keyframes string {
    0% {
        transform: rotate(3deg);
        transform-origin: bottom;
    }
    50% {
        transform: rotate(0deg);
        transform-origin: bottom;
    }
     100% {
        transform: rotate(3deg);
        transform-origin: bottom;
    }
}

@-moz-keyframes string {
    0% {
        transform: rotate(3deg);
        transform-origin: bottom;
    }
    50% {
        transform: rotate(0deg);
        transform-origin: bottom;
    }
     100% {
        transform: rotate(3deg);
        transform-origin: bottom;
    }
}

@keyframes string {
    0% {
        transform: rotate(3deg);
        transform-origin: bottom;
    }
    50% {
        transform: rotate(0deg);
        transform-origin: bottom;
    }
     100% {
        transform: rotate(3deg);
        transform-origin: bottom;
    }
}

