
/* table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  } */

thead {
    background-color: #b9cdee; 
}

tbody tr:hover {
    background-color: #f2f3f5;
}

.openmaterilas{
    width: 95%;
    height: 100vh;
    background-color: black;
    position:absolute;
    top: 0%;
    left: 5%;
    /* display: flex;
    justify-content: center; */
}

.deleteall{
    width: 85%;
    justify-content: right;
    display: flex;
}

.openmaterilasbutton{
    display: flex;
    justify-content: right;
}