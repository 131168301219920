/* same css is used in scrum.js and addproject */

.register-body {
    font-family: Arial, sans-serif;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.register-container {
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 560px;
    text-align: center;
    margin-top: 3%;
}

.register-header {
    background-color: #3498db;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: italic ;
}

.form-group {
    margin: 10px 0;
    text-align: left;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: #555;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

.form-group select {
    background-color: #f9f9f9;
    
}

.form-group button {
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    height: 40px;
}

.form-group button:hover {
    background-color: #2587c8;
}

.login-link {
    text-decoration: none; 
    background-color:rgb(47, 203, 255);
    color:#fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

.studetail{
    width: 100%;
}
