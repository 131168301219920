/* src/Night.css */
.night-sky {
    position: relative;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(180deg, #0d1b2a, #1b263b, #415a77, #778da9);
    overflow: hidden;
  }
  
  .star {
    position: absolute;
    width: 2px;
    height: 2px;
    background: white;
    border-radius: 50%;
    animation: twinkle 5s infinite ease-in-out;
  }
  
  .star:nth-child(1) { top: 20%; left: 30%; }
  .star:nth-child(2) { top: 40%; left: 70%; }
  .star:nth-child(3) { top: 60%; left: 50%; }
  .star:nth-child(4) { top: 80%; left: 20%; }
  .star:nth-child(5) { top: 50%; left: 80%; }
  
  .moon {
    position: absolute;
    width: 100px;
    height: 100px;
    background: #f4f4f4;
    border-radius: 50%;
    top: 10%;
    right: 10%;
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.5);
  }
  
  @keyframes twinkle {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
  }
  
  .count-icon h2{
    color: white;
  }