@keyframes colorChange {
  0% {
    color: red;
  }

  25% {
    color: white;
  }

  50% {
    color: green;
  }

  75% {
    color: blue;
  }

  100% {
    color: rgb(251, 0, 251);
  }
}

.color-animation {
  font-size: 50px;
  font-weight: bold;
  animation: colorChange 5s infinite;
  background: linear-gradient(to-l, #7928CA, #FF0080);
}