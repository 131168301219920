.timer-container {
  font-family: Arial, sans-serif;

  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
flex-direction: row;
  @media (max-width: 480px) {
    width: 80%;
    gap: 2px;
    margin-left: 10%;
  }
}

.starthackathon {
  width: 100%;
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
}



.timer-container-main {
  background: white;
  padding: 20px;

  gap: 20px;
  display: flex;
  flex-direction: row;
  margin: auto;

  @media (max-width: 480px) {
    width: 80vw;
    flex-direction: column;
    gap: 2px;
  }
}

.timer-container-main>div {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
}

input[type="time"],
input[type="text"] {
  width: calc(100% - 22px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.timer-container-main button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.timer-container-main button:hover {
  background-color: #0056b3;
}

.timers ul {
  list-style-type: none;
  padding: 0;
  max-height: 400px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.timers ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(61, 60, 60, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.timers ul::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.timers ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.timers ul li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  width: 390px;
  gap: 4px;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  background-color: #4b5666;
  color: #ffffff;
  border-radius: 5px;
}

.timers ul li div p {
  font-size: 15px;
}

.timers ul li button {
  padding: 5px 10px;
  background-color: #dc3545;
}

.timers ul li button:hover {
  background-color: #c82333;
}

.timer-block {
  background: white;
  padding: 20px;

  gap: 20px;
  display: flex;
  flex-direction: row;
  margin: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;

  @media (max-width: 480px) {
    width: 80vw;
    flex-direction: column;
    margin-left: 10%;
    gap: 2px;
  }
}