body {
    font-family: sans-serif;
    -webkit-font-smoothing: auto;
    -moz-font-smoothing: auto;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: auto;
    text-rendering: optimizeLegibility;
    font-smooth: always;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

h1 {
    font-size: 1.5rem;
}

.boxmotion{
    width: 100%;
    height: 100vh;
    align-items: center;
    display: grid;
    justify-content: space-evenly;
}


@media (max-width:500px) {
    #motion{
        display: none;
    }
    #signinform{
        width: 90%;
    }
    .boxmotion{
        display: none;
    }
    .astname{
       display: none;
    }
}