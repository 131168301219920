.count-icon {
  margin: 4%;
}

/* Button Styles */

@media (width<760px) {
  .scalable * {
    transform: scale(0.8);
  }
}

/* Countdown Styles */
.countdown .content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  gap: 12px;
}

.countdown h2 {
  margin-bottom: 20px;
  font-size: 50px;
}

.hack-title {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.h1-animation {
  font-size: 3.2rem;
  font-weight: bold;
  background: linear-gradient(
    270deg,
    rgb(12, 214, 46),
    rgb(137, 119, 244),
    rgb(218, 10, 218),
    #4f3aea,
    rgb(70, 1, 70),
    rgb(227, 22, 22),
    #34036c,
    rgb(31, 185, 219)
  );
  background-size: 400% 400%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: fadeIn 2s ease-in-out, gradientBackground 8s ease infinite;
  transition: transform 0.3s ease-in-out;
  text-align: center;
  margin-bottom: 1%;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.h1-animation-console {
  font-size: 4rem;
  font-weight: bold;
  background: linear-gradient(
    270deg,
    rgb(12, 214, 46),
    rgb(137, 119, 244),
    rgb(218, 10, 218),
    #4f3aea,
    rgb(70, 1, 70),
    rgb(227, 22, 22),
    #34036c,
    rgb(31, 185, 219)
  );
  background-size: 400% 400%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: fadeIn 2s ease-in-out, gradientBackground 8s ease infinite;
  transition: transform 0.3s ease-in-out;
  text-align: center;
  font-family: Georgia, "Times New Roman", Times, serif;
}

@media (max-width: 500px) {
  .h1-animation {
    margin-top: 20%;
    font-size: 1.5em;
  }
  .h1-animation-console {
    font-size: 1.5em;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes gradientBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.countdown .box {
  width: calc(100% / 4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  max-width: 150px;
  animation: flipIn 0.6s;
}

.countdown .value {
  font-size: 30px;
  font-weight: 600;
  background-color: #1c3136;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 80px;
  height: 80px;
  position: relative;
  box-shadow: -2px 0px 5px 1px #3b2064ad;
}

@media screen and (min-width: 550px) {
  .countdown .content {
    gap: 30px;
  }
  .countdown .value {
    font-size: 50px;
    width: 120px;
    height: 120px;
  }
  .countdown .box {
    height: 200px;
    max-width: 200px;
  }
}

@media screen and (min-width: 790px) {
  .countdown .value {
    font-size: 120px;
    height: 200px;
    width: 200px;
  }
  .countdown .box {
    max-width: 200px;
    height: 250px;
  }
}

.countdown .value::before {
  position: absolute;
  width: 100%;
  height: 1px;
  z-index: 2;
  background-color: #313131b0;
  box-shadow: 0px 0px 5px 0px #2e2f2ed6;
}

.countdown .value::after {
  position: absolute;
  z-index: 2;
  left: -7px;
  width: 10px;
  height: 20px;
  background-color: #969696;
  box-shadow: inset -1px 1px 5px 1px #0e0e0ead;
}

.countdown .label {
  position: relative;
  top: -50px;
  font-size: 20px;
  font-weight: 100;
  letter-spacing: 4px;
  margin-top: 10px;
}

@keyframes flipIn {
  0% {
    transform: rotateX(90deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

.background-attach {
  position: fixed;
  width: 100%;
  height: 100vh; /* Adjust the height as needed */

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}
