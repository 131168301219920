.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.upload-icon {
    width: 100px;
    cursor: pointer;
}

.mati{
    max-height: 100%;
    overflow-y: scroll;
}

.preview {
    /* width: 100%; */
    height: 20vh;
    justify-content: space-evenly;
    display: flex;
    margin-top: 20px;
    max-width: 300px;
}
