.morning-yoga {
    display: flex;
    justify-content: center;
    position: relative;
   bottom: 0;
    height: 100vh;
    width: 100%;
    background: linear-gradient(to right, #6c9dea, #2193b0);
  }
  
  .yoga-image {
    max-width: 100%;position:absolute;
    height: auto;
  }
  