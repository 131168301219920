.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 20px;
  flex-wrap: wrap;

  @media (max-width:500px) {
    flex-direction: column;
    
  }
}
.activity-controls,.background-controls {
  padding: 5px;
  width: 50%;

  @media (max-width:500px) {
    width: 100%;
    
  }
}
.activity-controls .controls{
  display: flex;
  flex-direction: row !important;
}


.image-mapping {
  display: flex;
  flex-direction: column!important;
  align-items: center;
  margin: 10px;
}
.images{
  display: flex;
  overflow-x: scroll;
  scrollbar-width: thin;

}
.images::-webkit-scrollbar {
  width: 2px;
}
.images::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
.images::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.images::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.mapping-img {
  width: auto;
  height: 50px;
  object-fit: contain;
  margin-right: 10px;
}
.mapping-img:hover{
  transform: scale(1.5);
}

.control-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.control-item select {
  margin-top: 10px;
  padding: 5px;
}
