@media only screen and (max-width:500px)
{


.clgname
{
  font-size: 60px;
  font-family: poppins;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.addproject
{
  width:100%;
  height: 40vh;
  margin: 45% 0% 0% 0%;
}
.addproject label
{
  margin-top: 3%;
  font-size: 25px;
}
.addproject input
{
  width: 90%;
  height: 5vh;
}
.projectlink
{
  padding: 5% 40%;
  width: 95%;
  font-size: 20px;
  border-radius: 15px;
}
.dailywork
{
  width: 100%;
  height: 35vh;
  margin-left: 0%;
}
table thead
{
  width: 50vh;
}
table tbody
{
  width: 50vh;
  font-size: 10px;
  margin: 0%;
  background-color: aliceblue;
}
table tbody p
{
  margin:15px 0px 1px 6%;
  font-size:10px;
}
table tbody button
{
  height:4vh;
  width:10vh;
  font-size: small;
}
.signup
{
  font-size: 20px;
  margin-left: 75%;
  padding: 2px;
 
}
.studentcheck{
  width: 98%;
  margin-left: 3px;
  margin-bottom: 3px;
  border: 1px solid black;
}
.complteday
{
  margin-left: 63%;
}
.yearbtns
{
  margin-left: 0%;
}
.dailywork td textarea
{
  width: 30vh;
  height: 12vh;
}
.scrmbtn
{
  width: 30vh;
  margin-left: 16%;
  height: 5vh;
}
.dailyattend table tr td
{
  width:0%;
}
Table
{
  font-size: 10px;
}
.otp
{
  width: 80%;
  height: 15vh;
  margin: 50% 0% 0% 35px;
}
.otp input
{
  height: 5vh;
  font-size:large;
  border-radius: 15px;
}
.otp button
{
  height: 4vh;
  margin:1px 20px;
}
}
