.others-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  

}

.marks-entry {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid pink;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  width: 300px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  
}

.marks-entry h2 {
  text-align: center;
  margin-bottom: 20px;

}

.search-section,
.marks-section {
  margin-bottom: 5px;
  width: 100%;
  
}

.search-section input,
.marks-section input {
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  background-color: white;
  

}

.marks-section button {
  padding: 10px;
  font-size: 16px;
  /* border: none; */
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: block;
  margin-left: 38%;

}



@media (max-width: 1200px) {
  .marks-entry {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .marks-entry {
    width: 70%;
  }
}

@media (max-width: 576px) {
  .marks-entry {
    width: 90%;
  }

  .search-section input,
  .marks-section input {
    font-size: 14px;
    padding: 8px;
  }

  .marks-section button {
    font-size: 14px;
    padding: 8px;
  }
}


.search-results {
  max-height: 250px;
  padding: 10px;
  overflow-y: auto;
  position: absolute;
  width: max-content;
  z-index: 1;
}
.search-results.onselect{
  display: none;
}

