.clgname {

 font-size: 4rem;
    font-weight: bold;
    background: linear-gradient(270deg,rgb(12, 214, 46),rgb(137, 119, 244),rgb(218, 10, 218), #4f3aea,rgb(70, 1, 70), rgb(227, 22, 22),#34036c,rgb(31, 185, 219));
    background-size: 400% 400%;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    animation: fadeIn 2s ease-in-out, gradientBackground 8s ease infinite;
    transition: transform 0.3s ease-in-out;
    text-align: center;
    /* margin-top:5%; */
    /* padding: 0; */
    margin-bottom: 1%;
    font-family:Georgia, 'Times New Roman', Times, serif;
    transform: scale(0.9);

}
.clgname:hover {
    overflow-x: hidden;

    transform: scale(1);
}

@media (max-width:500px) {
    .clgname{
        margin-top: 20%;
    }
  }

.yearbtns{
    text-align: center;
}

.containerattd {
    margin: 20px;
    font-family: Arial, sans-serif;
}

.studentcheck {
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.studetail {
    width: 100%;
    border-collapse: collapse;
}

.table-caption {
    caption-side: top;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
    font-size: 18px;
    color: #333;
}

thead th {
    background-color: #f2f2f2;
    padding: 10px;
    border-bottom: 2px solid #ddd;
    text-align: left;
    font-size: 16px;
}

tbody td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.table-row:nth-child(even) {
    background-color: #f9f9f9;
}

.table-row:hover {
    background-color: #e6f7ff;
}

.attend-button {
    background-color: #3498db;
    border-radius: 8px;
    border: none;
    color: white;
    padding: 5px;
    font-family: monospace;
    cursor: pointer;
    transition: background-color 0.3s;
}

.attend-button:hover {
    background-color: #2980b9;
}

.loading {
    background-color: white;
    text-align: center;
    height: 7vh;
}
