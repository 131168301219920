.hackboot{
    width: 60%;
    height: 30vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* background-color: black; */
    border-radius: 20px;
}
.hackboot button{
    width: 30%;
    height: 10vh;
    font-size: large;
    align-items: center;
}

@media (max-width:500px) {
    .hackboot{
        width: 80%;
        height: 30vh;
        display: grid;
    }
    .hackboot button{
        width: 100%;
        height: 8vh;
    }
}